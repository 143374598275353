import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, Modal, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import i18n from "../../i18n";
import { toTitleCase } from "../../utils/formatString";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import config from "../../config";
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import styled from "styled-components";

const StatusSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

class PrinterTable extends React.Component {
    constructor(props) {
        super(props);
        this.printerTypes = config?.printerTypes;
        this.state={
            printers:[],
            showPrintersModal: false,
            printerData: {},
            printerModalErrors: {},
        }
        this.groupIndex=props.groupIndex;
        this.updateParentState=props.updatePrinterData;
    }
    async componentDidMount() {
        if (this.props?.data) {
          let data = { children: [...this?.props?.data] };
          this.setState({
            items: { ...data },
          });
        }
      }
      componentWillReceiveProps(nextProps) {
        if (nextProps?.printers) {
          this.setState({
            printers: [ ...nextProps?.printers ]
          });
        }
      }
      async savePrinterData() {
        let validateData = await this.validatePrinterFormField();
        if (Object?.keys(validateData)?.length > 0) {
          return;
        }
        if (this.state.addPrinter) {
          let tempData = [...this.state?.printers];
          tempData.push(this.state.printerData);
          this.setState({
            addPrinter: false,
            printers: tempData,
            showPrintersModal: false,
          });
          if(this.groupIndex!=="" || this.groupIndex!==undefined){
            this.updateParentState(tempData,this.groupIndex)
          }else{
            this.updateParentState(tempData)
        }
        } else if (this.state.printerData?.editIndex !== "") {
          let tempData = [...this.state?.printers];
          tempData[this.state?.printerData?.editIndex] = this.state.printerData;
          delete tempData?.["editIndex"];
          this.setState({
            printers: tempData,
            showPrintersModal: false,
          });
          if(this.groupIndex!=="" || this.groupIndex!==undefined){
            this.updateParentState(tempData,this.groupIndex)
          }else{
            this.updateParentState(tempData)
        }
        }
      }
      async validatePrinterFormField(field) {
        let error = { ...this.state.printerModalErrors };
    
        if (field == null || field === "type") {
          if (
            !this.state?.printerData?.type ||
            this.state?.printerData?.type === ""
          ) {
            error.type = i18n.t("this_field_is_required");
          } else {
            delete error?.[field];
          }
        }
    
        if (field == null || field === "server_id") {
          if (
            !this.state?.printerData?.server_id ||
            this.state?.printerData?.server_id === ""
          ) {
            error.server_id = i18n.t("this_field_is_required");
          } else {
            delete error?.[field];
          }
        }
        if (field == null || field === "ip") {
          if (!this.state?.printerData?.ip || this.state?.printerData?.ip === "") {
            error.ip = i18n.t("this_field_is_required");
          } else {
            delete error?.[field];
          }
        }
    
        this.setState({
          printerModalErrors: error,
        });
    
        return error;
      }
      async handlePrinterDelete() {
        let tempData = [...this.state?.printers];
        delete tempData?.[this.state?.printerData?.deleteIndex];
        var filtered = tempData.filter(function (el) {
          return el != null;
        });
        if(this.groupIndex!=="" || this.groupIndex!==undefined){
          this.updateParentState(filtered,this.groupIndex)
        }else{
          this.updateParentState(filtered)
      }
        this.setState({
          printers: filtered,
          printerData: {},
        });
      }
render() {
    return (
      <>
       <Grid container spacing={2} className="mb-2 mt-4">
            <Grid item xs={8}>
              <Typography variant="h5">{i18n.t("printer_details")}</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className="d-flex"
              direction="row"
              justifyContent="flex-end"
            >
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={async() => {
                    await this.setState({
                        showPrintersModal: true,
                        addPrinter: true,
                        printerData: {},
                        printerModalErrors: {},
                      });
                }}
              >
                {i18n.t("add_printer_details")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              {this.state.printers?.length > 0 && (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Printer Type</TableCell>
                        <TableCell align="right">Server Id</TableCell>
                        <TableCell align="right">IP Address</TableCell>
                        <TableCell align="center">
                        {i18n.t("local_orders")}<br/>
                          <Typography variant="h7" component="h7">
                          {i18n.t("quick_order")}
                        </Typography>
                          </TableCell>
                        <TableCell align="center">
                        {i18n.t("local_orders")}<br/>
                        <Typography variant="h7"  component="h7">
                          {i18n.t("all_orders")}
                        </Typography></TableCell>
                        <TableCell align="center">
                        {i18n.t("online_orders")}<br/>
                        <Typography variant="h7"  component="h7">
                          {i18n.t("quick_order")}
                        </Typography></TableCell>
                        <TableCell align="center">
                        {i18n.t("online_orders")}<br/>
                        <Typography variant="h7"  component="h7">
                          {i18n.t("all_orders")}
                        </Typography></TableCell>
                        <TableCell align="center">
                        {i18n.t("print_when_completed")}
                          </TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.printers?.map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {toTitleCase(row?.type)}
                          </TableCell>
                          <TableCell align="right">{row?.server_id}</TableCell>
                          <TableCell align="right">{row?.ip}</TableCell>
                          <TableCell align="center">{row?.local_orders?.quick_order?<DoneIcon/>:<ClearIcon/>}</TableCell>
                          <TableCell align="center">{row?.local_orders?.all_orders?<DoneIcon/>:<ClearIcon/>}</TableCell>
                          <TableCell align="center">{row?.online_orders?.quick_order?<DoneIcon/>:<ClearIcon/>}</TableCell>
                          <TableCell align="center">{row?.online_orders?.all_orders?<DoneIcon/>:<ClearIcon/>}</TableCell>
                          <TableCell align="center">{row?.print_when_completed?<DoneIcon/>:<ClearIcon/>}</TableCell>
                          <TableCell align="right" className="d-flex">
                            {
                              <>
                                <Typography
                                  style={{ cursor: "pointer" }}
                                  onClick={async(e) => {
                                    e.stopPropagation();
                                    await this.setState({
                                      printerData: {
                                        ...this.state.printerData,
                                        showPrinterDeleteWarningModal: true,
                                        deleteIndex: index,
                                      },
                                    });
                                  }}
                                >
                                  <IconButton color="error">
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Typography>
                                <Typography
                                  style={{ cursor: "pointer" }}
                                  onClick={async(event) => {
                                    event.stopPropagation();
                                    await this.setState({
                                      printerData: { ...row, editIndex: index },
                                      showPrintersModal: true,
                                      printerModalErrors: {},
                                    });
                                  }}
                                >
                                  <IconButton>
                                    <EditIcon />
                                  </IconButton>
                                </Typography>
                              </>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
          <Dialog
          open={this.state.showPrintersModal}
          onClose={() => {
            this.setState({
              showPrintersModal: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
        >
          <Grid container>
          <DialogTitle id="dialog-title">
            {i18n.t("printer_details")}
              </DialogTitle>
              <DialogContent>
              <Typography id="dialog-description" sx={{ mt: 2 }}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      sx={{ marginTop: 1, marginBottom: 1 }}
                      single
                      id="printer_type"
                      options={this?.printerTypes}
                      value={toTitleCase(this.state.printerData?.type)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={i18n.t("printer_type")}
                          error={
                            this.state.printerModalErrors?.type ? true : false
                          }
                          helperText={this.state.printerModalErrors?.type}
                        />
                      )}
                      onChange={async (option, value) => {
                        await this.setState({
                          printerData: {
                            ...this.state.printerData,
                            type: value?.value,
                          },
                        });
                        await this.validatePrinterFormField("type");
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      fullWidth
                      type="text"
                      id="serverId"
                      label={i18n.t("server_id")}
                      name="server_id"
                      autoComplete="server id"
                      onChange={async (e) => {
                        await this.setState({
                          printerData: {
                            ...this.state.printerData,
                            server_id: e?.target?.value,
                          },
                        });
                        await this.validatePrinterFormField("server_id");
                      }}
                      error={
                        this.state.printerModalErrors?.server_id ? true : false
                      }
                      helperText={this.state.printerModalErrors?.server_id}
                      value={this.state.printerData?.server_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="ip"
                      fullWidth
                      label={i18n.t("ip_address")}
                      name="ip address"
                      autoComplete="ip address"
                      onChange={async (e) => {
                        await this.setState({
                          printerData: {
                            ...this.state.printerData,
                            ip: e?.target?.value,
                          },
                        });
                        await this.validatePrinterFormField("ip");
                      }}
                      error={this.state.printerModalErrors?.ip ? true : false}
                      helperText={this.state.printerModalErrors?.ip}
                      value={this.state.printerData?.ip}
                    />
                  </Grid>
                  <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <StatusSwitch
                            onChange={async (e) => {
                              await this.setState({
                                printerData: {
                                  ...this.state.printerData,
                                  print_when_completed:e?.target?.checked
                                },
                              });
                            }}
                            checked={this.state.printerData?.print_when_completed}
                            style={{ cursor: "pointer" }}
                          />
                        }
                        label={i18n.t("print_when_completed")}
                      />
                    </Grid>
                  <Grid item xs={6}>
                  <Typography variant="h6">{i18n.t("local_orders")}</Typography>
                  <FormControlLabel control={<Checkbox 
                  checked={this.state.printerData?.local_orders?.quick_order||false} 
                  onChange={async(e)=>{
                    await this.setState({
                      printerData: {
                        ...this.state.printerData,
                        local_orders:{
                          quick_order:e?.target?.checked,
                          all_orders:false
                        }
                      },
                    });
                  }}/>} label={i18n.t("print_only_if_quick_order")} />
                  <FormControlLabel control={<Checkbox 
                  checked={this.state.printerData?.local_orders?.all_orders||false} 
                  onChange={async(e)=>{
                    await this.setState({
                      printerData: {
                        ...this.state.printerData,
                        local_orders:{
                          quick_order:false,
                          all_orders:e?.target?.checked
                        }
                      },
                    });
                  }} />} label={i18n.t("print_all_orders")} />
                  </Grid>
                  <Grid item xs={6}>
                  <Typography variant="h6">{i18n.t("online_orders")}</Typography>
                  <FormControlLabel control={<Checkbox 
                  checked={this.state.printerData?.online_orders?.quick_order||false} 
                  onChange={async(e)=>{
                    await this.setState({
                      printerData: {
                        ...this.state.printerData,
                        online_orders:{
                          quick_order:e?.target?.checked,
                          all_orders:false
                        }
                      },
                    });
                  }} />} label={i18n.t("print_only_if_quick_order")} />
                  <FormControlLabel control={<Checkbox 
                  checked={this.state.printerData?.online_orders?.all_orders||false} 
                  onChange={async(e)=>{
                    await this.setState({
                      printerData: {
                        ...this.state.printerData,
                        online_orders:{
                          quick_order:false,
                          all_orders:e?.target?.checked
                        }
                      },
                    });
                  }} />} label={i18n.t("print_all_orders")} />
                  </Grid>
                </Grid>
              </Typography>
              </DialogContent>
              <DialogActions style={{ justifyContent: "flex-end" ,width:'100%'}}>
              <>
                <Button
                  variant="contained"
                  style={{ margin: "10px" }}
                  onClick={() => {
                    this.savePrinterData();
                  }}
                >
                  {i18n.t("Ok")}
                </Button>
                <Button
                  variant="contained"
                  style={{ margin: "10px" }}
                  onClick={() => {
                    this.setState({
                      showPrintersModal: false,
                    });
                  }}
                >
                  {i18n.t("cancel")}
                </Button>
              </>
              </DialogActions>
            </Grid>
          </Dialog>
        <Dialog
          open={this.state?.printerData?.showPrinterDeleteWarningModal}
          onClose={() => {
            this.setState({
              printerData: {},
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure of deleting , this action is irreversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handlePrinterDelete()}>Proceed</Button>
            <Button
              onClick={() => {
                this.setState({
                  printerData: {},
                });
              }}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
}
}

export default PrinterTable