import i18n from "./i18n";

const roles = {
    USER:{
        value: 5,
        key: "user"
    },
    SERVER:{
        value: 10,
        key: "server"
    },
    CASHIER:{
        value: 13,
        key: "cashier"
    },
    BAROWNER:{
        value: 15,
        key: "barowner"
    },
    ADMIN:{
        value: 20,
        key: "admin"
    },
    SUPERADMIN:{
        value: 30,
        key: "superadmin"
    }
}

const orderStatusOptions = [
    { label: i18n.t('completed'), value: 'completed' },
    { label: i18n.t('ready'), value: 'ready' },
    { label: i18n.t('processing'), value: 'processing' },
    { label: i18n.t('in_queue'), value: 'confirmed' },
    { label: i18n.t('pending'), value: 'pending' },
];

const orderTypeOptions = [
    { label: i18n.t('dine_in'), value: 'dine_in' },
    { label: i18n.t('take_away'), value: 'take_away' },
]
const paymentTypeOptions = [
    { label: i18n.t('payrexx'), value: 'payrexx' },
]
const currencyTypeOptions = [
    { label: 'CHF', value: 'CHF' },
]

const defaultVAT = [
    { value: 0 },
    { value: 2.1 },
    { value: 8.6 },
]

const defaultTable = [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 }
]
const tableLimitOptions=[
    {label:"10",value:10},
    {label:"25",value:25},
    {label:"50",value:50},
    {label:"100",value:100}
]
const printerTypes=[
    {label:"Epson",value:"epson"}
]
const paymentTypes=[
    {label:"Cash",value:"cash"},
    {label:"Card",value:"card"}
]
const consumptionRoundingTypes=[
    {label:"Quantity",value:"quantity"},
    {label:"Input",value:"input"}
]
const consumptionRoundingOptions=[
    {label:"1",value:1},
    {label:"0.05",value:0.05},
    {label:"0.1",value:0.1},
    {label:"0.5",value:0.5},
]
const constants = {
    applicationName: 'Kestuboua'
}

const primaryColors ={
    background : "#F0F0F0",
    blue : "#2065d1",
    white : "#ffffff"
}

export default {
    // baseUrl: '/api',    //live
    baseUrl: process.env.REACT_APP_BASE_URL,
    tokenKey: 'token',
    roles: roles,
    roleKey: 'role',
    orderStatusOptions: orderStatusOptions,
    currencyCode: 'CHF',
    version: '2.0.45',
    defaultVAT: defaultVAT,
    defaultTable:defaultTable,
    orderTypeOptions,
    paymentTypeOptions,
    currencyTypeOptions,
    constants,
    primaryColors,
    tableLimitOptions,
    printerTypes,
    paymentTypes,
    consumptionRoundingTypes,
    consumptionRoundingOptions,
}